@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  color-scheme: dark !important;
}

@layer components {
}

@theme {
  --text-2xs: 0.75rem;
  --text-2xs--line-height: 1.25rem;
  --text-xs: 0.8125rem;
  --text-xs--line-height: 1.5rem;
  --text-sm: 0.875rem;
  --text-sm--line-height: 1.5rem;
  --text-base: 1rem;
  --text-base--line-height: 1.75rem;
  --text-lg: 1.125rem;
  --text-lg--line-height: 1.75rem;
  --text-xl: 1.25rem;
  --text-xl--line-height: 1.75rem;
  --text-2xl: 1.5rem;
  --text-2xl--line-height: 2rem;
  --text-3xl: 1.875rem;
  --text-3xl--line-height: 2.25rem;
  --text-4xl: 2.25rem;
  --text-4xl--line-height: 2.5rem;
  --text-5xl: 3rem;
  --text-5xl--line-height: 1;
  --text-6xl: 3.75rem;
  --text-6xl--line-height: 1;
  --text-7xl: 4.5rem;
  --text-7xl--line-height: 1;
  --text-8xl: 6rem;
  --text-8xl--line-height: 1;
  --text-9xl: 8rem;
  --text-9xl--line-height: 1;

  --shadow-glow: 0 0 4px rgb(0 0 0 / 0.1);

  --container-lg: 33rem;
  --container-2xl: 40rem;
  --container-3xl: 50rem;
  --container-5xl: 66rem;
}

@layer base {
  :root {
    /* Hard-coded to Tailwind or your theme’s values */
    --shiki-color-text: #fafafa; /* was var(--color-white)      */
    --shiki-token-constant: #6ee7b7; /* emerald-300                */
    --shiki-token-string: #6ee7b7; /* emerald-300                */
    --shiki-token-comment: #71717a; /* zinc-500                   */
    --shiki-token-keyword: #7dd3fc; /* sky-300                    */
    --shiki-token-parameter: #f9a8d4; /* pink-300                   */
    --shiki-token-function: #c084fc; /* violet-300                 */
    --shiki-token-string-expression: #6ee7b7; /* emerald-300                */
    --shiki-token-punctuation: #e4e4e7; /* zinc-200                   */
  }

  [inert] ::-webkit-scrollbar {
    display: none;
  }
}

/* input[type="text"],
textarea {
  background-color: rgba(0, 0, 0, 0) !important;
} */

/* textarea {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
textarea:focus {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
input {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

 input:focus {
   padding: 0 !important;
  background-color: transparent !important; 
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
} */

#progress-bar {
  --scrollAmount: 0%;
  width: var(--scrollAmount);
  position: fixed;
  top: 88px;
}

/* DO NOT REMOVE */
.control-dots {
  z-index: -1 !important;
}
.carousel.carousel-slider {
  overflow: visible !important;
}
.carousel .slider-wrapper {
  overflow: visible !important;
}

.disable .remirror-theme .ProseMirror {
  min-height: 20px !important;
  overflow: hidden; /* Hide scrollbars */
}

.remirror-theme .ProseMirror {
  overflow: hidden !important; /* Hide scrollbars */
}

.remirror-editor {
  border: none !important;
  box-shadow: none !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Warning: react-select is a pain and this is the best way to override styles by far. */
.tc__control {
  @apply border-gray-200 bg-transparent text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-950 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.tc__control--is-focused {
  @apply ring-1 ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.tc__menu {
  @apply bg-white text-sm border border-gray-950 !important;
}

.tc__input-container {
  @apply text-gray-950 text-sm !important;
}

.tc__input {
  @apply text-gray-950 text-sm !important;
}

.tc__single-value {
  @apply text-gray-950 text-sm !important;
}

.tc__multi-value {
  @apply text-gray-950 bg-white border border-gray-500 rounded-md text-sm !important;
}

.tc__multi-value__label {
  @apply text-gray-950 text-sm !important;
}

/* Warning: react-select is a pain and this is the best way to override styles by far. */
.gh__control {
  @apply bg-gray-500 border-transparent bg-transparent text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.gh__control--is-focused {
  @apply ring-0 ring-gray-950 disabled:cursor-not-allowed disabled:opacity-50 !important;
}

.gh__menu {
  @apply bg-white border border-gray-200 text-sm !important;
}

.gh__single-value {
  @apply text-gray-950 text-sm !important;
}

.gh__input-container {
  @apply text-gray-950 text-sm !important;
}

.gh__option {
  @apply text-gray-950 text-sm !important;
}

.gh__option--is-focused {
  @apply bg-gray-600 text-sm !important;
}

.gh__option--is-selected {
  @apply bg-gray-400 text-sm !important;
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}

.filepond--panel-root {
  @apply border border-gray-200 border-dotted bg-transparent !important;
}

.remirror-suggest-atom {
  @apply border border-gray-200 bg-gray-950 text-white !important;
}

.remirror-mention-atom {
  @apply border border-gray-200 bg-gray-950 text-white !important;
}

.remirror-editor {
  @apply text-black !important;
}

.remirror-floating-popover {
  @apply bg-gray-950 text-white rounded !important;
}

.ProseMirror-focused {
  @apply shadow-none border border-gray-500 rounded-none !important;
}

.blog p {
  @apply max-w-3xl text-lg leading-8;
}

.blog p img {
  @apply rounded-2xl object-cover shadow-xl;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* fixes tooltip on pricing page */
#\:re\: {
  z-index: 9 !important;
}
